export const menuList = [
    {
        title: "Home",
        url: "/",
        cName: "navLinks",
        active: "activeItem"
        
    },
    {
        title: "Packages",
        url: "/services",
        cName: "navLinks",
        active: "activeItem"
        
    },
    {
        title: "Decor Zone",
        url: "/packages",
        cName: "navLinks",
        active: "activeItem"
        
    },
    {
        title: "Book Us",
        url: "/whyus",
        cName: "navLinks",
        active: "activeItem"
        
    },
    {
        title: "Our Team",
        url: "/contactus",
        cName: "navLinks",
        active: "activeItem"
        
    }
]